import { FC } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

/* eslint-disable max-len */
const TvBlock: FC<Props> = ({
  className,
}) => (
  <div {...{
    className: cx(styles.container, className),
  }}>
    <p>
      As Seen On:
    </p>

    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0032 0C7.1288 0 0 7.14075 0 16C0 24.8592 7.12912 32 16.0032 32C24.8768 32 32 24.8592 32 16C32 7.14075 24.8768 0 16.0032 0Z" fill="black"/>
      <path d="M5.50996 10.9838C2.72636 10.9838 0.49292 13.2236 0.49292 16.0003C0.49292 18.7771 2.72636 21.0167 5.50996 21.0167C7.21924 21.0167 7.8874 19.8115 7.8874 19.8115V20.9079H10.527V16.0002C10.527 13.2234 8.29356 10.9838 5.50996 10.9838V10.9838ZM5.50996 13.6066C6.83708 13.6066 7.90092 14.6754 7.90092 16.0004C7.90092 17.3254 6.83708 18.3941 5.50996 18.3941C4.18284 18.3941 3.119 17.3254 3.119 16.0004C3.119 14.6754 4.18284 13.6066 5.50996 13.6066Z" fill="white"/>
      <path d="M11.1663 7.52918V16.0002C11.1663 18.777 13.4014 21.0184 16.185 21.0184C18.9686 21.0184 21.1979 18.7769 21.1979 16.0002C21.1979 13.2234 18.9686 10.982 16.185 10.982C14.4757 10.982 13.8052 12.1905 13.8052 12.1905V7.52902L11.1663 7.52918ZM16.185 13.6063C17.5121 13.6063 18.5763 14.6754 18.5763 16.0004C18.5763 17.3253 17.5122 18.3944 16.185 18.3944C14.8579 18.3944 13.7937 17.3253 13.7937 16.0004C13.7937 14.6754 14.8579 13.6063 16.185 13.6063Z" fill="white"/>
      <path d="M26.6447 10.9838C23.8611 10.9838 21.6277 13.2236 21.6277 16.0003C21.6277 18.7771 23.8611 21.0167 26.6447 21.0167C29.0474 21.0167 31.041 19.3479 31.5414 17.1055H28.7662C28.3682 17.8702 27.571 18.3939 26.6447 18.3939C25.3176 18.3939 24.2538 17.3252 24.2538 16.0002C24.2538 14.6752 25.3176 13.6065 26.6447 13.6065C27.5738 13.6065 28.3751 14.1322 28.7719 14.9007H31.5413C31.0429 12.6555 29.0494 10.9838 26.6446 10.9838L26.6447 10.9838Z" fill="white"/>
    </svg>

    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.72979 12.5918C5.24199 12.2672 4.61481 12.1479 4.08943 12.1479C2.38348 12.1479 1 13.5286 1 15.2311C1 16.9337 2.38348 18.3144 4.08943 18.3144H14.3333L5.72979 12.5918Z" fill="#FCCC12"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.2703 12.5918C26.7581 12.2672 27.3853 12.1479 27.9107 12.1479C29.6166 12.1479 31.0001 13.5286 31.0001 15.2311C31.0001 16.9337 29.6166 18.3144 27.9107 18.3144H17.6667L26.2703 12.5918Z" fill="#05AC3F"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M28.5384 8.4517C28.5384 6.74917 27.155 5.36847 25.449 5.36847C24.2315 5.36915 23.1179 6.0803 22.6301 7.19304L18.3909 16.7905L27.1181 11.0461C27.9967 10.4679 28.5384 9.50104 28.5384 8.4517Z" fill="#069DE0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.51758 8.4517C3.51758 6.74917 4.90106 5.36847 6.60701 5.36847C7.82447 5.36915 8.93809 6.0803 9.42589 7.19304L13.6651 16.7905L4.93795 11.0461C4.05936 10.4679 3.51758 9.50104 3.51758 8.4517Z" fill="#FF7112"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5575 4.08387C15.5575 2.38135 14.174 1.00064 12.4681 1.00064C10.7621 1.00064 9.37866 2.38135 9.37866 4.08387C9.37866 4.56456 9.51325 5.02821 9.70796 5.46799L13.9937 15.1936L15.5199 4.55229C15.5418 4.39751 15.5562 4.24138 15.5575 4.08387Z" fill="#EF1541"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.0972 15.1936L22.3823 5.46734C22.577 5.02825 22.7116 4.56392 22.7116 4.08323C22.7116 2.3807 21.3281 1 19.6221 1C17.9162 1 16.5327 2.3807 16.5327 4.08323C16.5334 4.15687 16.5368 4.22983 16.543 4.30278H17.8957C18.2503 4.30278 18.4928 4.40437 18.6663 4.64574C17.9599 4.84824 16.7172 5.57644 16.8613 6.57941L18.0972 15.1936Z" fill="#6E55DC"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.8324 28.7723V20.516H9.01509V25.8656L5.02793 20.516H3.08423V28.7723H4.90154V23.426L8.87435 28.7723H10.8324Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.2778 28.7723C19.0049 28.7723 20.1903 28.0297 20.1903 26.2338C20.1903 25.299 19.7189 24.6922 18.9414 24.3315C19.5194 24.0615 19.9231 23.5869 19.9231 22.8233C19.9231 21.4549 18.8047 20.516 17.3072 20.516H12.4414V28.7723H17.2778ZM14.2574 22.0937H17.107C17.6522 22.0937 18.0191 22.3678 18.0191 22.8281C18.0191 23.3538 17.6467 23.6135 17.0974 23.6135H14.2574V22.0937ZM14.2574 25.192H17.0899C17.8373 25.192 18.306 25.5465 18.306 26.1786C18.306 26.8277 17.8407 27.1475 17.1172 27.1475H14.2574V25.192Z" fill="black"/>
      <path d="M21.1257 24.6234C21.1257 27.1168 23.2184 29 25.7606 29C27.3101 29 28.5788 28.3632 29.5093 27.4127L28.2529 25.9679C27.5294 26.6518 26.8065 27.08 25.7708 27.08C24.2193 27.08 23.0722 25.9789 23.0722 24.652C23.0722 23.4438 24.0929 22.2254 25.7059 22.2254C26.5852 22.2254 27.2718 22.6256 27.83 23.227L29.2258 21.8265C28.3103 20.9245 27.1598 20.304 25.7162 20.304C23.3707 20.3033 21.1257 22.0842 21.1257 24.6234Z" fill="black"/>
    </svg>

    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 3H32V29H0V3Z" fill="#003366"/>
      <path d="M32 21.6104H31.9404C31.5853 20.7383 31.1837 19.887 30.7354 19.0589H31.1292C31.4401 19.656 31.7303 20.2606 31.9998 20.8759L32 21.6104ZM0 6.56121C1.57524 6.03169 3.19489 5.65777 4.84262 5.44246L5.31162 3H9.11268L8.26028 5.21654C9.28366 5.214 10.3047 5.27371 11.3203 5.39566L12.5046 3H16.6814L14.6915 6.02639C17.3318 6.71152 19.8452 7.81207 22.1357 9.28907C22.5918 9.5823 23.0348 9.89121 23.4649 10.2105H22.8613C20.3299 8.42481 17.4926 7.11126 14.4894 6.34037L12.0563 10.1222H10.3098V12.697L6.00086 19.739H4.62239V22.0931L0.207117 29H0.067198L11.1599 5.72785C10.1572 5.61097 9.14674 5.55657 8.13877 5.56441L0.0312977 29H0.000230179M0 28.5666L4.76507 5.81107C3.15071 6.03676 1.56235 6.42105 0.0207117 6.95311H0" fill="white"/>
      <path d="M13.155 16.0635H15.4922V13.8754H13.155V12.832H16.0001L15.8445 10.6387H10.7763V18.6694H13.155V16.0635ZM23.0427 18.6462H25.6002L26.7662 16.6761L27.9608 18.6487H30.5779L28.054 14.4879L30.3263 10.6516H27.7353L26.7584 12.3179L25.7454 10.6463H23.1101L25.4499 14.5061L23.0427 18.6462ZM20.0681 18.7345C22.33 18.7656 24.1906 16.9592 24.2293 14.693C24.1333 12.3882 22.1926 10.5972 19.8893 10.6906C17.7205 10.7814 15.9844 12.5205 15.8937 14.693C15.9301 16.9617 17.7955 18.7706 20.0602 18.7345H20.0681ZM19.3321 12.9307C19.3346 12.5335 19.6483 12.2116 20.0421 12.1934C20.4489 12.2013 20.778 12.5256 20.7934 12.9307V16.3386C20.7649 16.7434 20.4151 17.0472 20.011 17.0186C19.6455 16.9926 19.3581 16.7019 19.3321 16.3386V12.9307ZM13.6965 24.6653H16.1165V23.0585H13.6965V21.9658H16.6451V20.2267H11.8285V27.7357H16.6817V25.9293H13.6965V24.6653ZM24.1128 20.2269L23.1852 24.5251L22.2782 20.2269H20.677L19.8142 24.5694L18.9151 20.2269H17.0055L18.7388 27.7359H20.48L21.3324 23.4324L22.2108 27.7359H23.9158L25.657 20.2269H24.1128ZM30.4352 23.6194C29.9376 23.1599 29.3676 23.038 28.7276 22.8563C27.971 22.6435 27.554 22.62 27.554 22.1788C27.585 21.8517 27.8752 21.6128 28.2018 21.6442H28.2071C28.689 21.6052 29.1294 21.9245 29.2408 22.3969L30.8756 21.8517C30.5726 20.7096 29.5154 19.9334 28.3364 19.9855C26.6288 19.9855 25.5847 21.0368 25.5847 22.4513C25.5847 23.6738 26.2947 24.2759 27.7171 24.7017C28.567 24.9587 29.0178 24.938 29.0178 25.4855C29.0178 25.7737 28.7509 25.9994 28.3079 25.9994C27.7222 26.0669 27.1885 25.6646 27.0875 25.083L25.2945 25.4774C25.6004 26.9023 26.6601 27.7486 28.2846 27.7486C29.0878 27.7694 29.8626 27.4501 30.417 26.8687C30.8264 26.4171 31.0519 25.8278 31.0466 25.2153C31.0802 24.6183 30.8549 24.0369 30.4326 23.6164H30.4352V23.6194ZM9.50166 23.9956L7.26802 20.2606H5.10181V27.741H7.0036V23.3702L9.55321 27.7309H11.3565V20.2502H9.50649L9.50166 23.9956Z" fill="white"/>
    </svg>
  </div>
);

export default TvBlock;
