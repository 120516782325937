import { NextRouter } from 'next/router';

// README: It's not working direct query params in URL attribute
export const creatHrefWithUTM = (router: NextRouter, url: string) => {
  // const queryKeys = Object.keys(router.query);
  const queryArray =  Object.keys(router.query).reduce((acc, next) => {
    const value = router.query[next];

    return value
      ? [...acc, `${next}=${value}`]
      : [...acc];
  }, [] as string[]);

  const prefix = queryArray.length ? '?' : '';
  const utmQueryString = prefix + queryArray.join('&');

  return url.startsWith('#')
    ? `${utmQueryString}${url}`
    : `${url}${utmQueryString}`;
};
