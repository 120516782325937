import { FC, PropsWithChildren } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
  id?: string;
}

const Page:FC<PropsWithChildren<Props>> = ({
  className,
  id,
  children,
}) => (
  <section {...{
    className: cx(styles.page, className),
    id,
  }}>
    { children }
  </section>
);

export default Page;
