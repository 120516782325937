import { FC } from 'react';
import { LinariaClassName, cx } from '@linaria/core';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const Logo: FC<Props> = ({
  className,
}) => (
  <div {...{
    className: cx(styles.logo, className),
  }}>
    {/* eslint-disable max-len */}
    <svg width="52" height="16" viewBox="0 0 52 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.4798 8.72727H0.425369C0.179733 8.72727 0 8.52542 0 8.30575V7.30835C0 7.06493 0.179733 6.88683 0.425369 6.88683H1.4798V4.1321C1.4798 1.37736 3.13934 0 5.29015 0C6.50035 0 7.44096 0.534323 7.84835 0.890538C8.00412 1.02115 8.02808 1.24675 7.93822 1.40111L7.37505 2.39852C7.26122 2.60037 7.03955 2.61818 6.81189 2.46382C6.51832 2.26197 5.87128 1.9295 5.33209 1.9295C4.34355 1.9295 3.64858 2.64193 3.64858 4.12616V6.88089H6.06899C6.31463 6.88089 6.49436 7.059 6.49436 7.30241V8.29981C6.49436 8.51948 6.31463 8.72134 6.06899 8.72134H3.64858V15.341C3.64858 15.5607 3.44489 15.7625 3.22322 15.7625H1.89918C1.67751 15.7625 1.47381 15.5607 1.47381 15.341V8.72727H1.4798Z" fill="currentColor"/>
      <path d="M8.89679 3.21781C8.89679 2.48757 9.45996 1.90575 10.1729 1.90575C10.9098 1.90575 11.4969 2.48163 11.4969 3.21781C11.4969 3.93024 10.9158 4.50612 10.1729 4.50612C9.45397 4.50612 8.89679 3.93024 8.89679 3.21781Z" fill="currentColor"/>
      <path d="M9.14243 7.30241C9.14243 7.08275 9.34613 6.88089 9.5678 6.88089H10.8439C11.0656 6.88089 11.2693 7.08275 11.2693 7.30241V15.341C11.2693 15.5607 11.0656 15.7625 10.8439 15.7625H9.5678C9.34613 15.7625 9.14243 15.5607 9.14243 15.341V7.30241Z" fill="currentColor"/>
      <path d="M15.1695 8.72727H14.2289C13.9832 8.72727 13.8035 8.52542 13.8035 8.30575V7.30835C13.8035 7.06494 13.9832 6.88683 14.2289 6.88683H15.1695V4.42301C15.1695 4.20334 15.3732 4.00148 15.5949 4.00148L16.871 3.97774C17.0926 3.97774 17.2724 4.17959 17.2724 4.39926V6.88683H19.561C19.8066 6.88683 19.9863 7.06494 19.9863 7.30835V8.30575C19.9863 8.52542 19.8066 8.72727 19.561 8.72727H17.2724V13.1265C17.2724 13.8805 17.5659 14.0171 17.9913 14.0171C18.4646 14.0171 19.0877 13.7499 19.3573 13.6193C19.6508 13.4887 19.7826 13.6193 19.8725 13.8212L20.2739 14.8007C20.3877 15.0442 20.2979 15.2223 20.0702 15.3351C19.8485 15.4657 18.5245 16 17.4461 16C15.6727 16 15.1575 14.9314 15.1575 13.3581V8.72727H15.1695Z" fill="currentColor"/>
      <path d="M26.0254 6.66122C28.5356 6.66122 30.5546 8.79258 30.5546 11.3039C30.5546 13.8568 28.5356 15.9881 26.0254 15.9881C23.5151 15.9881 21.4961 13.8568 21.4961 11.3039C21.4961 8.79258 23.5151 6.66122 26.0254 6.66122ZM26.0254 14.0824C27.4393 14.0824 28.5596 12.8356 28.5596 11.3039C28.5596 9.81373 27.4393 8.59666 26.0254 8.59666C24.6115 8.59666 23.4911 9.81966 23.4911 11.3039C23.4911 12.8356 24.6175 14.0824 26.0254 14.0824Z" fill="currentColor"/>
      <path d="M32.7953 1.59109C32.7953 1.37143 32.999 1.16957 33.2207 1.16957H34.4968C34.7185 1.16957 34.9222 1.37143 34.9222 1.59109V15.3469C34.9222 15.5666 34.7185 15.7685 34.4968 15.7685H33.2207C32.999 15.7685 32.7953 15.5666 32.7953 15.3469V1.59109Z" fill="currentColor"/>
      <path d="M37.726 3.21781C37.726 2.48757 38.2892 1.90575 39.0021 1.90575C39.739 1.90575 40.3261 2.48163 40.3261 3.21781C40.3261 3.93024 39.745 4.50612 39.0021 4.50612C38.2892 4.50612 37.726 3.93024 37.726 3.21781Z" fill="currentColor"/>
      <path d="M37.9776 7.30241C37.9776 7.08275 38.1813 6.88089 38.403 6.88089H39.6791C39.9008 6.88089 40.1045 7.08275 40.1045 7.30241V15.341C40.1045 15.5607 39.9008 15.7625 39.6791 15.7625H38.403C38.1813 15.7625 37.9776 15.5607 37.9776 15.341V7.30241Z" fill="currentColor"/>
      <path d="M46.6707 6.66122C49.181 6.66122 51.2 8.79258 51.2 11.3039C51.2 13.8568 49.181 15.9881 46.6707 15.9881C44.1604 15.9881 42.1414 13.8568 42.1414 11.3039C42.1414 8.79258 44.1604 6.66122 46.6707 6.66122ZM46.6707 14.0824C48.0846 14.0824 49.205 12.8356 49.205 11.3039C49.205 9.81373 48.0846 8.59666 46.6707 8.59666C45.2568 8.59666 44.1365 9.81373 44.1365 11.3039C44.1365 12.8356 45.2568 14.0824 46.6707 14.0824Z" fill="currentColor"/>
    </svg>
    {/* eslint-enable max-len */}
  </div>
);

export default Logo;
