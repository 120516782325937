import { FC } from 'react';
import Link from 'next/link';
import { cx, LinariaClassName } from '@linaria/core';

import Logo from '@/components/logo';

import HeadingMenuList from './components/list';
import HeadingMobileMenu from './components/mobileMenu';
import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
  withHeadingMenu: boolean;
}

const Heading: FC<Props> = ({
  withHeadingMenu,
  className,
}) => (
  <div {...{
    className: cx(styles.container, className),
  }}>
    <Link {...{
      href: '/',
    }}>
      <a {...{
        href: '/',
        className: styles.link,
      }}>
        <Logo {...{
          className: styles.logo,
        }} />
      </a>
    </Link>

    {
      withHeadingMenu && (
        <>
          <HeadingMenuList {...{
            className: styles.showDesktop,
          }} />

          <HeadingMobileMenu {...{
            className: styles.hideDesktop,
          }}>
            <HeadingMenuList />
          </HeadingMobileMenu>
        </>
      )
    }
  </div>
);

export default Heading;

