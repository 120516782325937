import { FC, PropsWithChildren } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import Heading from './components/heading';
import Footer from './components/footer';

import * as styles from './styles';
import './styles.global';

interface Props {
  withHeading?: boolean;
  withFooter?: boolean;
  withHeadingMenu?: boolean;
  className?: LinariaClassName;
  footerClassName?: LinariaClassName;
}

const MainPageNewLayout: FC<PropsWithChildren<Props>> = ({
  children,
  withHeading = true,
  withFooter = true,
  withHeadingMenu = true,
  footerClassName,
  className,
}) => (
  <div {...{
    className: cx(styles.mainPage, className),
  }}>
    <div {...{
      className: styles.mainPageInner,
    }}>
      {
        withHeading && (
          <Heading {...{
            withHeadingMenu,
            className: styles.heading,
          }} />
        )
      }

      <div {...{
        className: styles.contentContainer,
      }}>
        { children }
      </div>

      {
        withFooter && (
          <Footer {...{
            className: cx(styles.footer, footerClassName),
          }} />
        )
      }
    </div>
  </div>
);

export default MainPageNewLayout;
